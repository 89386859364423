<template>
    <div class="ImgDiamond-MAIN" @mouseover="fadeImages(true)" @mouseleave="fadeImages(false)">
        <img src="../assets/add.svg" class="add-img-hover">
        <div class="blue-hover" @click="switchPopUpProx(true)"></div>
        <div :style="`background-image:url('${imgUrl[0]}');`" alt="" class="inner-img" ref="imgDiamondElement"></div>
    </div>
</template>

<script>
export default {
    name: 'ImgDiamond',
    props: {
        imgUrl: Array,
    },
    data(){
        return{
            fadeInterval: undefined,
            intervalActive: false,
            currentPositionArray: 0,
        }
    },
    methods:{
        switchPopUpProx(mode_){
            this.$parent.switchPopUpProx(mode_);
        },

        fadeImages(active_){
            console.log(active_);
            clearInterval(this.fadeInterval);
            this.currentPositionArray = 0;
            if(this.imgUrl.length <= 1){
                this.intervalActive = false;
                return;
            }

            const img = this.$refs.imgDiamondElement;
            if(active_){
                this.intervalActive = true;
                this.fadeInterval = setInterval(()=>{
                    console.log("active interval")
                    if(this.currentPositionArray+1 < this.imgUrl.length){
                        this.currentPositionArray++;
                    }else{
                        this.currentPositionArray = 0;
                    }
                    img.style.backgroundImage = `url('${this.imgUrl[this.currentPositionArray]}')`;
                }, 2000);
            }else{
                this.intervalActive = false;
                clearInterval(this.fadeInterval);
                this.currentPositionArray = 0;
                img.style.backgroundImage = `url('${this.imgUrl[this.currentPositionArray]}')`;
                console.log("Interval cleared");
            }
        }
    }
}
</script>

<style scoped>
.ImgDiamond-MAIN{
    width: 10ch !important;
    scale: 2;
    aspect-ratio: 1/1 !important;
    overflow: hidden;
    border-radius: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.inner-img{
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
    background-position: center;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    border-radius: 100vw;
}

.blue-hover{
    position: absolute;
    z-index: 2;
    background-color: #2482E9;
    width: 92%;
    height: 92%;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    opacity: 0;
}

.add-img-hover{
    opacity: 0;
    position: absolute;
    z-index: 3;
    width: 16%;
    pointer-events: none;
}

.ImgDiamond-MAIN:hover{
    cursor: pointer;
}

.ImgDiamond-MAIN:hover .blue-hover{
    opacity: 0.4;
    transition: opacity 0.1s;
}

.ImgDiamond-MAIN:hover .add-img-hover{
    opacity: 1;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    .ImgDiamond-MAIN{
        width: 14vw !important;
    }

}
</style>