<template>
    <main class="ViewHome-MAIN">

        <MainCover/>

        <section>
            <div class="INNER-MARGIN">
                <h1><img src="../assets/house-building.svg"><b>¡Nuevos proyectos!</b></h1>
                <div class="new-projects">
                    <div data-aos-duration="1000" data-aos="fade-down"><hr><p><img src="../assets/house-building.svg">Una torre residencial de tres fachadas en una de las colonias de mayor tradición de la CDMX.</p><img src="../assets/plus.svg"><ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgH1-B.png'), require('../assets/imgH1-A.png'), require('../assets/imgH1-C.png')]"/></div>
                    <div data-aos-duration="1000" data-aos="fade-up"><hr><p><img src="../assets/house-building.svg">En Sand Diamante te ofrecemos opción de renta y venta para que disfrutes de Acapulco Diamante al máximo.</p><img src="../assets/plus.svg"><ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgH2.png'), require('../assets/imgH2-A.png'), require('../assets/imgH2-B.png'), require('../assets/imgH2-C.png')]"/></div>
                    <div data-aos-duration="1000" data-aos="fade-down"><hr><p><img src="../assets/house-building.svg">Renta de habitaciones privadas con áreas compartidas y amenidades vanguardistas en una de las mejores zonas de la CDMX.</p><img src="../assets/plus.svg"><ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgH3.png'), require('../assets/imgH3-A.png'), require('../assets/imgH3-B.png'), require('../assets/imgH3-C.png')]"/></div>
                    <div data-aos-duration="1000" data-aos="fade-up"><hr><p><img src="../assets/house-building.svg">Excelente oportunidad de inversión con una gran variedad de amenidades.</p><img src="../assets/plus.svg"><ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgH4-A.png'), require('../assets/imgH4-B.png'), require('../assets/imgH4-C.png')]"/></div>
                </div>
            </div>
        </section>

        <section>
            <div class="INNER-MARGIN">
                <h1 id="scroll-here-departamentos"><img src="../assets/building.svg">The One &nbsp;<b>Departamentos</b></h1>
                <p class="main-p">Gerencia de obra y desarrollo de <b>exclusivos departamentos</b> The One.</p>
                <div class="h-tiles-wrapper">
                    <div class="text-block h-tiles" data-aos="fade-up" data-aos-duration="1000">
                        <div>
                            <img src="../assets/users-home.svg">
                            <h3>Sand Diamante venta</h3>
                            <p></p>
                            <p>Una vida de comodidad te espera en nuestros exclusivos departamentos. Con una ubicación privilegiada, diseño moderno y amenidades de primera, nuestros departamentos ofrecen el escenario perfecto para disfrutar de un hogar acogedor hecho a tu medida. <br><br>Encuentra comodidad y diseño único en cada detalle.</p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgH5.png')]"/>
                    </div>
                    <div class="text-block h-tiles" data-aos="fade-up" data-aos-duration="1000">
                        <div>
                            <img src="../assets/users-home.svg">
                            <h3>Guadalupe Inn</h3>
                            <p></p>
                            <p>Una torre residencial de tres fachadas en una de las colonias de mayor tradición de la CDMX. Su estilo y ubicación estratégica convierten este desarrollo en una experiencia única. <br><br>Con increíbles vistas y acceso a las vialidades más importantes de la zona, Guadalupe Inn es calidad de vida.</p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgH6.png')]"/>
                    </div>
                </div>
                <div class="h-tiles-wrapper h-tiles-last">
                    <div class="text-block h-tiles" data-aos="fade-up" data-aos-duration="1000">
                        <div>
                            <img src="../assets/users-home.svg">
                            <h3>Departamento Mitikah <br><span class="proximamente"><br></span></h3>
                            <p></p>
                            <p>Excelente oportunidad de inversión con una gran variedad de amenidades, vistas panorámicas en una zona de gran plusvalía con ubicación estratégica que tiene acceso a importantes vialidades y al centro comercial.                            </p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgH9.png')]"/>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="INNER-MARGIN" style="position: relative;">
                <h1 id="scroll-here-coliving"><img src="../assets/corporativo.svg">The One &nbsp;<b>Co-living</b></h1>
                <p class="main-p">Gerencia de obra y desarrollo de <b>exclusivos departamentos</b> The One.  </p>
                <div class="h-tiles-wrapper">
                    <div class="text-block h-tiles" data-aos="fade-up" data-aos-duration="1000">
                        <div>
                            <img src="../assets/users.svg">
                            <h3>Emerson Polanco</h3>
                            <p></p>
                            <p>Renta de habitaciones privadas con áreas compartidas y amenidades vanguardistas en una de las mejores zonas de la CDMX. <br><br>Creamos comunidades de personas que gustan de compartir experiencias inolvidables. Con servicios incluidos, áreas totalmente equipadas y un diseño único. Mejora tu estilo de vida con su ubicación privilegiada.</p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgH7.png')]"/>
                    </div>
                    <div class="text-block h-tiles" data-aos="fade-up" data-aos-duration="1000">
                        <div>
                            <img src="../assets/users.svg">
                            <h3>Sand Diamante renta</h3>
                            <p></p>
                            <p>Con Sand Diamante disfruta de las mejores playas, restaurantes y entretenimiento. Acapulco Diamante es conocido por su seguridad y tranquilidad, también por su cercanía a los puntos de interés más destacados del puerto, convirtiéndose en el lugar ideal para vacacionar. <br><br>Una gran experiencia, donde la tranquilidad y el confort se fusionan en un escenario natural inigualable.</p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgH8.png')]"/>
                    </div>
                </div>

                <img src="../assets/subir.svg" class="subir" @click="goUp()">
            </div>
        </section>

        <section style="display: none !important;">
            <div class="INNER-MARGIN" style="position: relative;">
                <h1 id="scroll-here-hoteles"><img src="../assets/users-home.svg">One &nbsp;<b>Hoteles</b></h1>
                <p class="main-p">Desarrollamos oficinas con soluciones personalizadas que reflejan la <b>identidad de las marcas y mejoran la experiencia de los colaboradores.</b></p>
                <div class="h-tiles-wrapper h-tiles-last">
                    <div class="text-block h-tiles" data-aos="fade-up" data-aos-duration="1000">
                        <div>
                            <img src="../assets/users-home.svg">
                            <h3>Departamento Mitikah <br><span class="proximamente"><br></span></h3>
                            <p></p>
                            <p>Excelente oportunidad de inversión con una gran variedad de amenidades, vistas panorámicas en una zona de gran plusvalía con ubicación estratégica que tiene acceso a importantes vialidades y al centro comercial.                            </p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgH9.png')]"/>
                    </div>
                </div>

            </div>
        </section>

    </main>
</template>

<script>
import MainCover from './MainCover.vue';
import ImgDiamond from './ImgDiamond.vue';

export default {
    name: 'ViewHome',
    components: {
        MainCover,
        ImgDiamond,
    },
    methods: {
        goUp(){
            window.scrollTo(0, 0);
        },

        switchPopUpProx(mode_){
            this.$parent.switchPopUpProx(mode_);
        }
    }
}
</script>

<style scoped>
.ViewHome-MAIN section{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ViewHome-MAIN *{
    font-family: 'Montserrat', sans-serif;
}

.ViewHome-MAIN > section:nth-child(odd){
    background-color: #EDEDED;
}

.text-block{
    border-radius: 1ch;
}

.ViewHome-MAIN > section:nth-child(even) .text-block{
    background-color: #EDEDED;
}

.ViewHome-MAIN > section:nth-child(odd) .text-block{
    background-color: white;
}

.INNER-MARGIN{
    display: flex;
    flex-direction: column;
}

/* text format */
h1{
    text-align: center;
    color: #202945;
    text-transform: uppercase;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.3ch;
    margin-bottom: 0;
}

h1 img{
    max-width: 2ch;
    max-height: 2ch;
    margin-right: 1ch;
}

h1 b{
    font-weight: 700;
}

.main-p{
    color: #202945;
    text-wrap: balance;
    max-width: 59ch;
    margin: auto;
    margin-top: 2ch;
    margin-bottom: 2ch;
}
/* end text format */

.new-projects{
    display: flex;
    margin: auto;
    margin-top: 4ch;
    margin-bottom: 4ch;
    width: 100%;
}

.new-projects > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5ch;
}

.new-projects > div:nth-child(odd){
    flex-direction: column-reverse;
}

.new-projects > div > img{
    width: 4ch;
}

.new-projects > div:nth-child(odd) > img{
    margin-top: 7ch;
    z-index: 2;
}

.new-projects > div:nth-child(even) > img{
    margin-bottom: 7ch;
    margin-top: -7ch;
    z-index: 2;
}

.new-projects > div:nth-child(odd) > hr{
    position: absolute;
    z-index: 0;
    width: 4ch;
    rotate: 90deg;
    transform: translateX(-24vh);
}

.new-projects > div:nth-child(even) > hr{
    position: absolute;
    z-index: 0;
    width: 4ch;
    rotate: 90deg;
    transform: translateX(14vh);
}

@media all and (max-width:1136px){

    .new-projects > div:nth-child(odd) > hr{
        transform: translateX(-14vw);
    }

    .new-projects > div:nth-child(even) > hr{
        transform: translateX(6vw);
    }

}

.new-projects > div:nth-child(even) p{
    margin-bottom: 8ch;
    margin-top: -5ch;
}

.new-projects p{
    scale: 0.9;
    width: fit-content;
    margin-left: 2ch;
}

.new-projects p img{
    max-width: 2ch;
    max-height: 2ch;
    margin-left: -2.5ch;
    margin-right: 0.5ch;
}

.new-projects .img-diamond{
    margin: 7ch 0;
    scale: 2.4;
    z-index: 2;
}

.text-block h3{
    color: #3D7CC9;
    font-weight: 200;
    font-size: 2ch;
}

.h-tiles h3{
    margin-top: 0.4ch;
    margin-bottom: 0;
}

.h-tiles > div > img:nth-child(1){
    width: 2.2ch;
    height: 2.2ch;
}

.h-tiles > div > p:nth-child(3){
    margin-top: 0.8ch;
    text-transform: uppercase;
    font-weight: 100;
    color: #3A3A3A;
    letter-spacing: 0.5ch;
    font-size: 1.2ch;
}

.h-tiles{
    padding: 1ch;
    position: relative;
}

.h-tiles > div > p:nth-child(4){
    font-size: 1.3ch;
    width: 62%;
    line-height: 2.2ch;
}

.h-tiles-wrapper .text-block{
    min-height: 33ch !important;
}

.h-tiles-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 5ch 28ch;
    margin-top: 5ch;
    margin-bottom: 8ch;
    width: 75%;
}

.h-tiles .img-diamond{
    position: absolute;
    bottom: 12ch;
    right: -14ch;
    z-index: 2;
}

.h-tiles-last{
    grid-template-columns: 1fr;
    width: 28ch;
    margin: auto;
    margin-top: 1ch;
    margin-bottom: 4ch;
    transform: translateX(-7vw);
}

.h-tiles-last > div{
    padding-top: 2ch;
    padding-bottom: 3ch;
}

.proximamente{
    font-size: 0.95ch;
    margin-top: 0;
    line-height: 0;
}

.img-diamond{
    scale: 3.6 !important;
}

.subir{
    width: 2ch;
    position: absolute;
    bottom: 2ch;
    right: 2ch;
    cursor: pointer;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    /* text format */
    h1{
        font-size: 5.5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 80%;
        margin: auto;
        margin-top: 1.4ch;
        margin-bottom: 5vw;
    }

    h1 img{
        max-width: 9vw;
        max-height: 9vw;
        margin-bottom: 2vw;
        margin-right: 0;
    }

    .main-p{
        text-align: center;
        font-size: 4.5vw;
        margin-top: 0;
    }
    /* end text format */

    .new-projects{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        font-size: 4vw;
    }

    .new-projects > div{
        margin-top: 0;
    }

    .new-projects > div:nth-child(odd) {
        margin-right: -3ch;
        margin-bottom: 7vw !important;
    }

    .new-projects > div:nth-child(even) {
        margin-left: -3ch;
        margin-bottom: 28vw;
    }

    .new-projects > div:nth-child(odd) > img{
        margin-top: 0vw;
    }

    .new-projects > div:nth-child(even) > img{
        margin-bottom: -0vw;
        margin-top: -10vw;
    }

    .new-projects > div:nth-child(even) p{
        margin-bottom: 17vw;
        margin-top: 0;
    }

    .new-projects > div:nth-child(odd) > hr{
        display: none;
    }

    .new-projects > div:nth-child(even) > hr{
        display: none;
    }

    .text-block h3{
        font-size: 4.5vw;
    }

    .h-tiles-wrapper{
        display: flex;
        flex-direction: column;
    }

    .h-tiles, .h-tiles-last{
        width: 58vw;
        margin-left: 4vw;
    }

    .h-tiles .img-diamond, .h-tiles-last .img-diamond{
        right: -11vw;
    }

    .h-tiles > div > p:nth-child(4), .h-tiles-last > div > p:nth-child(4){
        font-size: 3.4vw;
    }

    .h-tiles-last{
        margin-left: 7vw;
    }

    .h-tiles-last .img-diamond{
        right: -9vw;
    }
}
</style>