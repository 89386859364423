<template>
    <main class="ViewUs-MAIN">

        <section>
            <div class="INNER-MARGIN" data-aos="fade-down" data-aos-duration="1000">
                <h1><img src="../assets/case.svg"><b>Quienes somos</b></h1>
                <div class="about">
                    <img src="../assets/about.png">
                    <div>
                        <h2>The One Inmobiliaria</h2>
                        <p>Somos una empresa mexicana con <b>más de 30 años de experiencia en proyectos inmobiliarios de calidad.</b></p>
                        <p>Estamos comprometidos con ofrecer <span>espacios de calidad que se adapten a sus objetivos.</span></p>
                        <button class="BTN">Contáctanos</button>
                    </div>
                    <div class="arrows">
                        <img src="../assets/arrow.svg">
                        <img src="../assets/arrow.svg">
                    </div>
                </div>
            </div>
        </section>

        <section class="ONLY-PC">
            <div class="INNER-MARGIN">
                <h1><img src="../assets/corporate-case.svg">NUESTROS &nbsp;<b>PROYECTOS</b></h1>
                <p class="main-p p-adjust-center">Estamos comprometidos con ofrecer soluciones, por eso nos dedicamos a proyectar, administrar, construir y supervisar la construcción de espacios funcionales, cómodos, prácticos y estéticos.</p>
                <div class="text-block block-three" data-aos="fade-up" data-aos-duration="1000">
                    <div><img src="../assets/building.svg"><h3>Portal Presidentes</h3><p>Departamentos</p></div>
                    <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgUs1.png')]"/>
                    <p>Este edificio en el corazón de la delegación Benito Juárez está diseñado para quienes buscan seguridad y calidad en una zona céntrica que conecta con vialidades importantes de la CDMX.</p>
                </div>
                <div class="text-block block-three" data-aos="fade-up" data-aos-duration="1000">
                    <div><img src="../assets/bed.svg"><h3>Hotel One</h3><p>One Hoteles</p></div>
                    <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgUs2.png')]"/>
                    <p>El hospedaje perfecto para descansar o para viajes de negocios. Nuestro hotel ofrece una mezcla de comodidad y practicidad que convierte tu estadía en una experiencia única.</p>
                </div>
            </div>
        </section>

        <section class="ONLY-PC">
            <div class="INNER-MARGIN">
                <h1><img src="../assets/silla-de-oficina.svg">The One &nbsp;<b>Working</b></h1>
                <p class="main-p">Desarrollamos oficinas con soluciones personalizadas que reflejan la <b>identidad de las marcas y mejoran la experiencia de los colaboradores.</b></p>
                <div class="text-block block-three" data-aos="fade-up" data-aos-duration="1000">
                    <div><img src="../assets/silla-de-oficina.svg"><h3>Gabriel Mancera</h3><p>The One Working</p></div>
                    <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgUs3.png')]"/>
                    <p>Espacios de trabajo amplios, funcionales y cómodos con diseños innovadores. En el corazón de la colonia del Valle, con conexión a vías principales.</p>
                </div>
                <div class="text-block block-three" data-aos="fade-up" data-aos-duration="1000">
                    <div><img src="../assets/silla-de-oficina.svg"><h3>IDEEO</h3><p>The One Working</p></div>
                    <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgUs4.png')]"/>
                    <p>El diseño de nuestras oficinas incentiva la creatividad y cuenta con espacios iluminados  y amplios para fomentar el bienestar de los colaboradores.</p>
                </div>
            </div>
        </section>

        <section class="ONLY-CEL">
            <div class="INNER-MARGIN">
                <h1><img src="../assets/corporate-case.svg">NUESTROS &nbsp;<b>PROYECTOS</b></h1>
                <p class="main-p">Lorem ipsum dolor sit amet, <b>consectetuer adipiscing</b> elit.</p>
                <div class="manufacturing-tiles-wrapper">
                    <div class="text-block manufacturing-tiles" data-aos="fade-up" data-aos-duration="1000">
                        <div>
                            <img src="../assets/building.svg">
                            <h3>Portal Presidentes</h3>
                            <p>Manufacturing</p>
                            <p>Este edificio en el corazón de la delegación Benito Juárez está diseñado para quienes buscan seguridad y calidad en una zona céntrica que conecta con vialidades importantes de la CDMX.</p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgUs1.png')]"/>
                    </div>
                    <div class="text-block manufacturing-tiles" data-aos="fade-up" data-aos-duration="1000">
                        <div>
                            <img src="../assets/bed.svg">
                            <h3>Hotel One</h3>
                            <p>Manufacturing</p>
                            <p>El hospedaje perfecto para descansar o para viajes de negocios. Nuestro hotel ofrece una mezcla de comodidad y practicidad que convierte tu estadía en una experiencia única.</p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgUs2.png')]"/>
                    </div>
                </div>
            </div>
        </section>

        <section class="ONLY-CEL">
            <div class="INNER-MARGIN">
                <h1><img src="../assets/silla-de-oficina.svg">The One &nbsp;<b>Working</b></h1>
                <p class="main-p">Desarrollamos oficinas con soluciones personalizadas que reflejan la <b>identidad de las marcas y mejoran la experiencia de los colaboradores.</b></p>
                <div class="manufacturing-tiles-wrapper">
                    <div class="text-block manufacturing-tiles" data-aos="fade-up">
                        <div>
                            <img src="../assets/silla-de-oficina.svg">
                            <h3>Gabriel Mancera</h3>
                            <p>Manufacturing</p>
                            <p>Espacios de trabajo amplios, funcionales y cómodos con diseños innovadores. En el corazón de la colonia del Valle, con conexión a vías principales.</p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgUs3.png')]"/>
                    </div>
                    <div class="text-block manufacturing-tiles" data-aos="fade-up">
                        <div>
                            <img src="../assets/silla-de-oficina.svg">
                            <h3>IDEEO</h3>
                            <p>Manufacturing</p>
                            <p>El diseño de nuestras oficinas incentiva la creatividad y cuenta con espacios iluminados  y amplios para fomentar el bienestar de los colaboradores.</p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgUs4.png')]"/>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="INNER-MARGIN">
                <h1 id="scroll-here-manufacturing"><img src="../assets/factory.svg">The One &nbsp;<b>Manufacturing</b></h1>
                <p class="main-p">Contamos con una amplia gama de naves industriales <b>diseñadas para satisfacer las necesidades específicas de cada negocio.</b></p>
                <div class="manufacturing-tiles-wrapper">
                    <div class="text-block manufacturing-tiles" data-aos="fade-up" data-aos-duration="1000">
                        <div>
                            <img src="../assets/farm.svg">
                            <h3>Calle 8</h3>
                            <p>Manufacturing</p>
                            <p>Las proporciones, arquitectura y diseño de nuestras naves cuentan con la ISO 45001 de sistemas de gestión de seguridad y salud en el trabajo.</p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgUs5.png')]"/>
                    </div>
                    <div class="text-block manufacturing-tiles" data-aos="fade-up" data-aos-duration="1000">
                        <div>
                            <img src="../assets/farm.svg">
                            <h3>Nave 2</h3>
                            <p>Manufacturing</p>
                            <p>Naves para uso comercial, fabricación y almacenamiento, suministros de materiales, distribución mayorista, almacenaje y de uso estacional.</p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgUs6.png')]"/>
                    </div>
                    <div class="text-block manufacturing-tiles" data-aos="fade-up" data-aos-duration="1000">
                        <div>
                            <img src="../assets/farm.svg">
                            <h3>Nave 3</h3>
                            <p>Manufacturing</p>
                            <p>Creamos espacios seguros con estructuras de acero y control de temperatura interna para mantener la calidad de los materiales / productos.</p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgUs7.png')]"/>
                    </div>
                    <div class="text-block manufacturing-tiles" data-aos="fade-up" data-aos-duration="1000">
                        <div>
                            <img src="../assets/farm.svg">
                            <h3>Calle 5</h3>
                            <p>Manufacturing</p>
                            <p>Espacios optimizados y organizados que minimizan los contratiempos logísticos y revalorizan el inmueble a largo plazo.</p>
                        </div>
                        <ImgDiamond class="img-diamond" :imgUrl="[require('../assets/imgUs8.png')]"/>
                    </div>
                </div>
            </div>
        </section>

    </main>
</template>

<script>
import ImgDiamond from './ImgDiamond.vue';

export default {
    name: 'ViewUs',
    components: {
        ImgDiamond
    },
    methods:{
        switchPopUpProx(mode_){
            this.$parent.switchPopUpProx(mode_);
        }
    }
}
</script>

<style scoped>
.ViewUs-MAIN section{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ViewUs-MAIN *{
    font-family: 'Montserrat', sans-serif;
}

.ViewUs-MAIN > section:nth-child(even){
    background-color: #EDEDED;
}

.text-block{
    border-radius: 1ch;
    color: #202945;
}

.ViewUs-MAIN > section:nth-child(odd) .text-block{
    background-color: #EDEDED;
}

.ViewUs-MAIN > section:nth-child(even) .text-block{
    background-color: white;
}

.INNER-MARGIN{
    display: flex;
    flex-direction: column;
}

/* about */
.about{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6vh;
    margin-bottom: 6vh;
    position: relative;
}

.about > img{
    max-width: 40%;
    max-height: 75vh;
}

.about > div{
    width: 55%;
}

.about h2{
    font-size: 2.7ch;
    text-transform: uppercase;
    color: #3D7CC9;
}

.about > div > p:nth-child(2){
    font-size: 1.9ch;
    font-weight: 600;
    max-width: 90%;
    text-wrap: balance;
}

.about > div > p:nth-child(3){
    font-weight: 600;
    text-wrap: balance;
}

.about span {
    color: #3D7CC9;
}

.about button{
    background-color: #3D7CC9;
    color: white;
    margin-top: 3ch;
}

.arrows{
    position: absolute;
    right: 0;
    min-width: unset;
    width: 1ch !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.arrows img{
    width: 3.6ch;
    margin:  5ch 0;
}

.arrows > img:nth-child(2){
    rotate: 180deg;
}

/* text format */
h1{
    text-align: center;
    color: #202945;
    text-transform: uppercase;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.3ch;
    margin-bottom: 0;
}

h1 img{
    max-width: 2ch;
    max-height: 2ch;
    margin-right: 1ch;
}

h1 b{
    font-weight: 700;
}

.main-p{
    color: #202945;
    text-wrap: balance;
    max-width: 59ch;
    margin: auto;
    margin-top: 2ch;
    margin-bottom: 2ch;
}
/* end text format */

.text-block h3{
    color: #3D7CC9;
    font-weight: 200;
    font-size: 2ch;
}

.block-three{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8ch;
    margin-bottom: 8ch;
    padding: 2.5ch 1ch;
}

.block-three h3, .manufacturing-tiles h3{
    margin-top: 0.4ch;
    margin-bottom: 0;
}

.block-three > div:nth-child(1){
    width: fit-content;
    min-width: 30%;
    margin-left: 1ch;
}

.block-three > div:nth-child(1) > img,
.manufacturing-tiles > div > img:nth-child(1){
    width: 2.2ch;
    height: 2.2ch;
}

.block-three > div:nth-child(1) > p,
.manufacturing-tiles > div > p:nth-child(3){
    margin-top: 0.8ch;
    text-transform: uppercase;
    font-weight: 100;
    color: #3A3A3A;
    letter-spacing: 0.5ch;
    font-size: 1.2ch;
}

.block-three > p{
    width: 45%;
    margin-right: 1ch;
}

.block-three .img-diamond{
    margin-left: -4vw !important;
}

.manufacturing-tiles{
    padding: 1ch;
    position: relative;
}

.manufacturing-tiles > div > p:nth-child(4){
    font-size: 1.4ch;
    width: 50%;
    line-height: 2.2ch;
}

.manufacturing-tiles-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 8ch 28ch;
    margin-top: 5ch;
    margin-bottom: 14ch;
    width: 85%;
}

.block-three .img-diamond{
    margin-right: 5%;
}

.manufacturing-tiles .img-diamond{
    position: absolute;
    bottom: 6ch;
    right: -7ch;
}

.img-diamond{
    scale: 3 !important;
}

.p-adjust-center{
    width: fit-content !important;
    transform: translateX(4vw);
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    /* about */
    .about{
        flex-direction: column;
        align-items: center;
    }

    .about > img{
        max-width: unset;
        max-height: unset;
        width: 100%;
    }

    .about > div{
        width: 80%;
    }

    .about h2{
        font-size: 5.5vw;
        text-align: center;
        margin-top: 8vw;
        margin-bottom: 8vw;
    }

    .about p{
        font-size: 4vw !important;
        max-width: unset !important;
        width: 100% !important;
        font-weight: 500 !important;
    }

    .about button{
        margin-left: 18vw;
        font-size: 4vw;
    }

    .arrows{
        display: none;
    }

    /* text format */
    h1{
        font-size: 5.5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 80%;
        margin: auto;
        margin-top: 1.4ch;
        margin-bottom: 5vw;
    }

    h1 img{
        max-width: 9vw;
        max-height: 9vw;
        margin-bottom: 2vw;
        margin-right: 0;
    }

    .main-p{
        text-align: center;
        font-size: 4.5vw;
        margin-top: 0;
    }
    /* end text format */

    .text-block h3{
        font-size: 6.5vw;
    }

    .manufacturing-tiles-wrapper{
        display: flex;
        flex-direction: column;
    }

    .manufacturing-tiles{
        width: 80vw;
        height: 100vw;
        margin-left: 4vw;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .manufacturing-tiles .img-diamond{
        left: unset;
        right: unset;
        margin: auto !important;
        bottom: -7vw;
    }

    .manufacturing-tiles > div:nth-child(1){
        margin: 3vw;
    }

    .manufacturing-tiles > div > p:nth-child(4){
        font-size: 3.4vw;
        width: 100%;
    }






}
</style>