<template>
    <main class="OneInmobiliaria-MAIN">

        <NavBar :currentPage="currentPage" class="ONLY-PC"/>
        <NavBarMobile class="ONLY-CEL"/>

        <ViewHome v-if="currentPage == 'home'"/>
        <ViewUs v-if="currentPage == 'us'"/>

        <section class="popup-proximamente" v-if="popUpProxActive">
            <div>
                <div class="popup-close" @click="switchPopUpProx(false)">+</div>
                <h2>Próximamente</h2>
                <button class="BTN" @click="switchPopUpProx(false)">Aceptar</button>
            </div>
        </section>

        <FooterComponent :currentPage="currentPage"/>

    </main>
</template>

<script>
import NavBar from './NavBar.vue';
import NavBarMobile from './NavBarMobile.vue';
import FooterComponent from './FooterComponent.vue';
import ViewHome from './ViewHome.vue';
import ViewUs from './ViewUs.vue';
import AOS from 'aos';
import 'aos/dist/aos.css'; 

export default {
    name: 'OneInmobiliaria',
    components: {
        NavBar,
        NavBarMobile,
        FooterComponent,
        ViewHome,
        ViewUs,
    },
    data(){
        return{
            currentPage: 'home', // 'home' | 'us'
            popUpProxActive: false,
        }
    },
    methods: {
        switchPopUpProx(mode_){
            this.popUpProxActive = mode_;
        }
    },

    mounted(){
        AOS.init();
    }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");

.OneInmobiliaria-MAIN{
    overflow-x: hidden;
}

*{
  scroll-behavior: smooth;
}

.INNER-MARGIN {
  width: 900px;
  width: 1080px;
  max-width: 90vw;
}

.BTN{
    border: none;
    padding: 1.5ch 2.5ch;
}

.BTN:hover{
    cursor: pointer;
}

.ONLY-PC{
    display: flex !important;
}

.ONLY-CEL{
    display: none !important;
}

.popup-proximamente{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
    z-index: 20;
}

.popup-proximamente > div{
    background-color: white;
    padding: 6ch 10ch;
    border-radius: 1ch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    position: relative;
}

.popup-close{
    width: 1;
    height: 1ch;
    position: absolute;
    top: 0ch;
    right: 0ch;
    rotate: 45deg;
    font-size: 3.6ch;
    color: gray;
    cursor: pointer;
}

.popup-proximamente button{
    background-color: #3D7CC9;
    color: white;
    margin-top: 1ch;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    .INNER-MARGIN{
        max-width: 95.01vw;
        width: 95vw;
    }

    .ONLY-PC{
        display: none !important;
    }

    .ONLY-CEL{
        display: flex !important;
    }

    .popup-proximamente > div{
        padding: 6ch 0;
        width: 90vw;
    }

    .popup-proximamente h2{
        font-size: 8.5vw;
    }

}
</style>