<template>
    <footer class="FooterComponent-MAIN" :style="currentPage == 'home' ? 'background-color:#EDEDED;' : ''">
        <div>
            <img src="../assets/logo.svg">
        </div>
        <div>
            <p><b>The One Inmobiliaria</b></p>
            <p>
                Calle: Año de Juarez #223
                <br>Granjas San Antonio,
                <br>Iztapalapa Col. Granjas San Antonio
                <br>C.P. 09070, Ciudad de México
            </p>
        </div>
        <div>
            <div><img src="../assets/wa.svg"><p>56 3243 2372</p></div>
            <div><img src="../assets/fb.svg"><p>Theoneinmobiliariamx</p></div>
            <div><img src="../assets/ig.svg"><p>theoneinmobiliaria_mx</p></div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',
    props:{
        currentPage: String,
    }
}
</script>

<style scoped>
footer{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 2ch 0;
}

footer *{
    font-family: 'Montserrat', sans-serif;
    color: #202945;
}

footer > div:nth-child(1) img{
    scale: 0.9;
}

footer > div:nth-child(3){
    display: flex;
    flex-direction: column;
    scale: 0.9;
}

footer > div:nth-child(3) div{
    display: flex;
    align-items: center;
}

footer > div:nth-child(3) img{
    width: 2ch;
    height: 2ch;
    margin-right: 1ch;
}

footer > div:nth-child(3) p{
    margin: 0.8ch 0;
    font-weight: 800;
}

/********************************************************************
* RESPONSIVE
********************************************************************/
@media all and (max-width:780px){

    footer{
        flex-direction: column;
        padding: 4vw 0;
        font-size: 5vw;
        border-bottom: 12vw solid #003567;
    }

    footer > div:nth-child(1){
        display: flex;
        align-items: center;
        justify-content: center;
    }

    footer > div:nth-child(1) img{
        scale: unset;
        width: 75%;
        margin-top: 2vw;
        margin-bottom: 8vw;
    }

    
    footer > div:nth-child(3){
        margin-top: 6vw;
    }

}
</style>