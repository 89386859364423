<template>
  <OneInmobiliaria/>
</template>

<script>
import OneInmobiliaria from './components/OneInmobiliaria.vue'

export default {
  name: 'App',
  components: {
    OneInmobiliaria,
  }
}
</script>

<style>

</style>
