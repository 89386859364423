<template>
    <nav class="NavBarMobile-MAIN">
        <div class="mobile-bar">
            <img src="../assets/logo.svg" class="img-logo" @click="manageBtn('logo')">
            <img src="../assets/apps.svg" class="img-logo" @click="manageBtn('menu')">
        </div>
        <section class="mobile-menu" v-if="menuActive">
            <div class="mobile-menu-logo">
                <img src="../assets/circle-xmark.svg" class="close-menu" @click="menuActive = false">
                <img src="../assets/logo.svg" class="img-logo" @click="manageBtn('logo')">
            </div>
            <div @click="manageBtn('somos')"><img src="../assets/case.svg"><p>Quienes somos</p></div>
            <div @click="manageBtn('departamentos')"><img src="../assets/building.svg"><p>Residencial</p></div>
            <div @click="manageBtn('coliving')"><img src="../assets/corporativo.svg"><p>Coliving</p></div>
            <div @click="manageBtn('manufacturing')"><img src="../assets/factory.svg"><p>Industrial</p></div>
            <div @click="manageBtn('hoteles')"><img src="../assets/dormitorio.svg"><p>Oficinas</p></div>
        </section>
    </nav>
</template>

<script>
export default {
    name: 'NavBarMobile',
    data(){
        return{
            menuActive: false,
        }
    },
    methods: {
        manageBtn(btn_){
            if(btn_ == "logo"){
                window.location.reload();
                return;
            }else
            if(btn_ == "menu"){
                this.menuActive = true;
                return;
            }
            if(btn_ == 'somos'){
                this.$parent.currentPage = "us";
                this.menuActive = false;
                return;
            }else
            if(btn_ == "departamentos"){
                this.$parent.currentPage = "home";
            }else
            if(btn_ == "coliving"){
                this.$parent.currentPage = "home";
            }else
            if(btn_ == "manufacturing"){
                this.$parent.currentPage = "us";
            }else
            if(btn_ == "hoteles"){
                this.$parent.currentPage = "home";
            }

            this.menuActive = false;
            setTimeout(() => {
                document.getElementById(`scroll-here-${btn_}`).scrollIntoView();
            }, 80);
        }
    }
}
</script>

<style scoped>
nav *{
    font-family: 'Montserrat', sans-serif;
}

.mobile-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2vw 3vw;
    width: 100%;
}

.mobile-bar > img:nth-child(1){
    width: 30vw;
}

.mobile-bar > img:nth-child(2){
    width: 8vw;
}

.mobile-menu{
    background-color: #EDEDED;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
}

.mobile-menu > div{
    display: flex;
    align-items: center;
    font-size: 5vw;
    width: 70%;
    margin-bottom: 2vh;
}

.mobile-menu > div:not(:nth-child(1)) img{
    margin-right: 1ch;
    width: 6vw;
}

.mobile-menu > div:nth-child(1){
    background-color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 55vw;
}

.mobile-menu > div:nth-child(1) img:not(.close-menu){
    width: 50vw;
}

.close-menu{
    position: absolute;
    top: 4vw;
    left: 4vw;
    width: 7vw;
}
</style>