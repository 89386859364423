<template>
    <nav :class="`NavBar-MAIN nav-on-${currentPage}`" data-aos="fade-down" data-aos-duration="1000">
        <div class="nav-wrapper">
            <div>
                <img src="../assets/logo.svg" class="img-logo" @click="manageBtn('logo')">
            </div>
            <div class="nav-buttons">
                <div @click="manageBtn('somos')"><img src="../assets/case.svg"><p>Quienes somos</p></div>
                <div @click="manageBtn('departamentos')"><img src="../assets/building.svg"><p>Residencial</p></div>
                <div @click="manageBtn('coliving')"><img src="../assets/corporativo.svg"><p>Coliving</p></div>
                <div @click="manageBtn('manufacturing')"><img src="../assets/factory.svg"><p>Industrial</p></div>
                <div @click="manageBtn('hoteles')"><img src="../assets/dormitorio.svg"><p>Oficinas</p></div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavBar',
    props:{
        currentPage: String,
    },
    methods: {
        manageBtn(btn_){
            if(btn_ == "logo"){
                window.location.reload();
                return;
            }else
            if(btn_ == 'somos'){
                this.$parent.currentPage = "us";
                return;
            }else
            if(btn_ == "departamentos"){
                this.$parent.currentPage = "home";
            }else
            if(btn_ == "coliving"){
                this.$parent.currentPage = "home";
            }else
            if(btn_ == "manufacturing"){
                this.$parent.currentPage = "us";
            }else
            if(btn_ == "hoteles"){
                this.$parent.currentPage = "home";
            }

            setTimeout(() => {
                document.getElementById(`scroll-here-${btn_}`).scrollIntoView();
            }, 80);
        }
    }
}
</script>

<style scoped>
.NavBar-MAIN, .nav-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

nav *{
    font-family: 'Montserrat', sans-serif;
}

.nav-wrapper{
    justify-content: space-between;
    width: 100%;
    max-width: 1100px;
}

.nav-wrapper > div{
    display: flex;
    align-items: center;
    margin: 0 2ch;
}

.img-logo{
    max-width: 17ch;
    margin: 1ch 0;
    cursor: pointer;
}

.nav-buttons > div{
    margin-left: 2ch;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.nav-buttons > div p{
    margin: 0;
    font-size: 1.5ch;
}

.nav-buttons > div img{
    margin: 0;
    max-width: 1.5ch;
    max-height: 1.5ch;
    margin-right: 0.5ch;
}

/* conditionals */
.nav-on-home{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
}

.nav-on-home .img-logo{
    filter: brightness(0) invert(1) drop-shadow(0 0.5ch 0.5ch rgba(0,0,0,0.8));   
}

.nav-on-home .nav-buttons{
    color: white;
}

.nav-on-home .nav-buttons > div{
    background-color: rgba(0, 0, 0, 0.25);
    padding: 1ch;
}

.nav-on-home .nav-buttons img{
    filter: brightness(0) invert(1);
}

.nav-on-us{
    position: relative;
}
</style>